import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TextField } from '@mui/material';
import { useAuth } from '@veripass/react-sdk';
import { InventoryItemService } from '@services';

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  return entityResponse;
}

const Container = styled.article`
  width: ${(props) => (props.$isPopup ? '800px' : '100%')};
  ${(props) => (props.$isPopup ? '' : 'flex-grow: 1;')};

  @media (max-width: 1199px) {
    width: ${(props) => (props.$isPopup ? '700px' : '100%')};
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.$isPopup ? '600px' : '100%')};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.$isPopup ? '500px' : '100%')};
  }

  @media (max-width: 575px) {
    width: ${(props) => (props.$isPopup ? '100%' : '100%')};
  }
`;

const initialState = {
  name: '',
  description: '',
  quantity: 1,
  metadata: {
    custom_id: '',
    report: '',
  },
  supplier_id: '',
};

function ItemMediaUpdate({ entity = null, onUpdatedEntity = () => {}, isPopupContext = false }) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [entityData, setEntityData] = useState(entity ?? initialState);

  const handleDataChange = (fieldName, data) => {
    setEntityData((prevItemData) => ({
      ...prevItemData,
      [fieldName]: data,
    }));
  };

  const getLogActions = () => {
    // Detect changes in color properties
    const originalEntity = entity?.properties?.media || {};
    const modifiedFields = Object.keys(entityData).reduce(
      (acc, key) => {
        if (entityData[key] !== originalEntity[key]) {
          acc.updated[key] = entityData[key];
          acc.original[key] = originalEntity[key];
        }
        return acc;
      },
      { original: {}, updated: {} },
    );

    return {
      details: 'Media updated',
      metadata: modifiedFields,
      user: { id: user.identity || '', metadata: user.payload },
    };
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      // Setup random ui settings
      var updatedEntity = entityData;

      if (updatedEntity._id) {
        delete updatedEntity._id;
      }

      updatedEntity.log = getLogActions();

      const entityResponse = await updateEntity(updatedEntity, InventoryItemService);

      if (!entityResponse || !entityResponse.success) {
        onUpdatedEntity('update', null);
        return null;
      }

      // Update parent states
      setIsLoading(false);
      onUpdatedEntity('update', entityResponse.result);
    } catch (error) {
      setIsLoading(false);
      onUpdatedEntity('update', null);
    }
  };

  useEffect(() => {
    if (entity) {
      setEntityData(entity);
    }
  }, [entity]);

  return (
    <section>
      <Container $isPopup={isPopupContext} className={!isPopupContext ? 'col-12 my-2' : ''}>
        <form onSubmit={handleSubmit}>
          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="fullname-input"
                label="Itemname"
                value={entityData?.username}
                placeholder="jhon.doe"
                helperText="Enter a name that will be displayed on the user's profile."
                required
                onChange={(event) => {
                  handleDataChange('username', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="fullname-input"
                label="Display name"
                value={entityData?.display_name}
                placeholder="Jhon Doe"
                helperText="Enter a name that will be displayed on the user's profile."
                required
                onChange={(event) => {
                  handleDataChange('display_name', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-0 h-25 d-flex justify-content-end align-items-end">
              {isLoading ? (
                <button type="button" disabled className="btn btn-dark">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Saving changes
                </button>
              ) : (
                <button type="submit" className="btn btn-dark waves-effect waves-light">
                  Save changes
                </button>
              )}
            </section>
          </article>
        </form>
      </Container>
    </section>
  );
}

export default ItemMediaUpdate;
